import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFetch } from "../api/useFetch";
import Loading from "./Loading";

function CategoryButton({
  index,
  category,
  selectedCategory,
  onCategoryClick,
}) {
  const catButtonClasses = "btn m-0.5 drop-shadow-xl hover:drop-shadow-xl";
  return (
    <button
      key={index}
      className={`${
        category.name === selectedCategory.name ? "" : "btn-outline"
      } ${catButtonClasses}`}
      onClick={() => onCategoryClick(category)}
    >
      <span className="font-abel">{category.name}</span>
    </button>
  );
}

export default CategoryButton;
