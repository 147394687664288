import React, { useState } from "react";
import { useFetch } from "../api/useFetch";

import Loading from "./Loading";

function Image(props) {
  let imageId = props.imageId;
  const image = useFetch(
    `https://wp.danieljkahn.com/wp-json/wp/v2/media/${imageId}`
  );

  let [loaded, setLoaded] = useState(false);

    
    let imgStyles = props.objectFit ? `object-${props.objectFit}` : '';
    let figureStyles = props.objectFit ? `h-full w-full` : '';
  return (
    image && (
      <figure className={figureStyles}>
        <div className="h-v30">{loaded ? null : <Loading />}</div>
        <img
          style={loaded ? {} : { display: "none" }}
          className={imgStyles}
          src={image.source_url}
          alt={image.alt_text}
          onLoad={() => setLoaded(true)}
        />
      </figure>
    )
  );
}

export default Image;
