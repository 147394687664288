// import React, { useEffect, useState } from "react";
import React, { useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loading from "./Loading";
// import { useFetch } from "../api/useFetch";
import Head from "./Head";

import ReactGA from "react-ga4";

function Page(props) {
  const page = props.data;
  const seo = page?.yoast_head_json;
  const seoTitle = seo?.title;
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location,
      title: seoTitle,
    });
  }, [location, seoTitle]);

  if (!page) return <Loading />;

  return (
    page && (
      <>
        <Head
          title={seo.title}
          description={seo.og_description}
          keywords=""
          url={page.link}
          canonical={page.link}
          ogImagePath=""
          seo={seo}
        />
        <div className="container prose lg:prose-2xl prose-slate">
          <h1 className="no-underline uppercase font-abel">
            {page.title.rendered}
          </h1>
          <div
            className="font-abel"
            dangerouslySetInnerHTML={{ __html: page.content.rendered }}
          />
        </div>
      </>
    )
  );
}

export default Page;
