import { Link } from "react-router-dom";
import React from "react";

function Header(props) {
  let navLinks = [
    // { title: "Work", slug: "work" },
    // { title: "About", slug: "about" },
    { title: "Portfolio", slug: "portfolio" },
    { title: "Contact", slug: "contact" },
    // { title: "Posts", slug: "posts" },
  ];
  // const header = useFetch(`https://danieljkahn.com/wp-json/wp/v2/pages?slug=${slug}`);
  return (
    <header className="flex justify-center relative">
      <nav className="navbar bg-base-100">
        <div className="flex-1">
            <Link className="flex justify-center flex-col absolute px-5" to="/">
              <span className="font-abel text-xl">DK</span>
            </Link>
        </div>
        <div className="flex-none">
          <ul className="menu menu-horizontal px-1">
          {navLinks.map((link, index) => {
            return (
              <li className="m-1" key={index}>
                <Link
                  className="btn btn-ghost normal-case text-xl font-abel"
                  to={link.slug}
                >
                  {link.title}
                </Link>
              </li>
            );
          })}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default React.memo(Header);
