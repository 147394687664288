import React from "react";

const PortfolioFilterItem = ({ item, onFilterItemClick }) => {
  return (
    <div className="form-control flex px-0 py-0">
      <label className="label cursor-pointer justify-between w-full font-abel">
        <span className="label-text">{item.name}</span>
        <input
          type="checkbox"
          className="checkbox"
          onClick={(e) => onFilterItemClick(e, item)}
        />
      </label>
    </div>
  );
};

export default React.memo(PortfolioFilterItem);
