import { Link } from "react-router-dom";
import React from "react";

function Footer(props) {
  let navLinks = [
    // { title: "Work", slug: "work" },
    { title: "About", slug: "about" },
    { title: "Portfolio", slug: "portfolio" },
    { title: "Contact", slug: "contact" },
    // { title: "Posts", slug: "posts" },
  ];
  // const header = useFetch(`https://danieljkahn.com/wp-json/wp/v2/pages?slug=${slug}`);
  return (
    <footer className="h-10vw"></footer>
  );
}

export default Footer;
