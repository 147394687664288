import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useFetch } from "../api/useFetch";
import Loading from "./Loading";
import Image from "./Image";

function Hero(props) {
  return (
    <div className="w-full before:content-[''] before:w-full before:h-full before:absolute before:left-0 before:bg-black before:bg-opacity-70 relative h-30vw overflow-hidden">
      <Image objectFit={'cover'} imageId={props.imageId} />
      <div className="hero-copy prose lg:prose-2xl prose-slate flex absolute w-full h-full left-0 top-0 justify-center items-center">
        <h1 className="no-underline font-abel">{props.title}</h1>
      </div>
    </div>
  );
}

export default Hero;
