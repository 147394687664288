import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFetch } from "../api/useFetch";

import Loading from "./Loading";

import videojs from "video.js";
import Video from "./Video";

function Asset(props) {
  let assetId = props.assetId;

  const asset = useFetch(
    `https://wp.danieljkahn.com/wp-json/wp/v2/media/${assetId}`
  );

  let [loaded, setLoaded] = useState(false);

    

  return (
    asset && getAssetComponent(asset)
  );
}

function getAssetComponent(asset) {
  const comps = {
    'video': <Video data={asset} />,
    // 'image': <Image data={asset} />,
    // 'document': <Document data={asset} />
  }
  return comps[Object.keys(comps).find(k=> asset.mime_type.includes(k))];
}


export default Asset;
