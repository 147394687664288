import React, { useEffect, useState, memo, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useFetch } from "../api/useFetch";
import Loading from "./Loading";
import CategorySorter from "./CategorySorter";

import { FaFilter } from "react-icons/fa";
import PortfolioFilterItem from "./PortfolioFilterItem";

function PortfolioFilter({
  filterSelections,
  updateFilterSelections,
  selectedCategory,
  handleCategoryClick,
}) {
  const categories = useFetch(
    "https://wp.danieljkahn.com/wp-json/wp/v2/categories/?per_page=100"
  );
  const tags = useFetch(
    "https://wp.danieljkahn.com/wp-json/wp/v2/tags/?per_page=100"
  );

  const [selectedItems, setSelectedItems] = useState({
    categories: [],
    tags: [],
  });

  const handleFilterItemClick = useCallback((e, item) => {
    const isChecked = e.target.checked;
    const k = item.taxonomy.includes("cat") ? "categories" : "tags";

    setSelectedItems((prevState) => {
      const updatedArray = isChecked
        ? [...new Set([...prevState[k], item])]
        : prevState[k].filter((i) => {
            return i.id !== item.id;
          });
      return { ...prevState, [k]: updatedArray };
    });
  }, []);

  useEffect(() => {
    if (
      Object.values(selectedItems).every((arr) => {
        return Array.isArray(arr) && arr.length === 0;
      })
    ) {
      updateFilterSelections({ categories, tags });
    } else {
      updateFilterSelections(selectedItems);
    }
  }, [selectedItems, categories, tags]);

  if (!categories || !tags) return <Loading />;
  return (
    <div>
      <details className="dropdown">
        <summary className="btn btn-outline hover:drop-shadow-xl m-0.5 font-abel">
          Filter <FaFilter />
        </summary>
        <ul className="menu dropdown-content bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
          {/* <span className="font-bold">Categories</span> */}
          {/* {categories &&
            categories.map((category, index) => {
              if (category.name !== "Uncategorized") {
                return (
                  <li key={index}>
                    <PortfolioFilterItem
                      item={category}
                      name={category.name}
                      onFilterItemClick={handleFilterItemClick}
                    />
                  </li>
                );
              }
            })} */}
          <span className="font-bold font-abel">Tags</span>
          {tags &&
            tags.map((tag, index) => {
              return (
                <li key={index}>
                  <PortfolioFilterItem
                    item={tag}
                    name={tag.name}
                    onFilterItemClick={handleFilterItemClick}
                  />
                </li>
              );
            })}
        </ul>
      </details>

      {/* <div class="drawer drawer-end">
        <input id="my-drawer-4" type="checkbox" class="drawer-toggle" />
        <div class="drawer-content">
          <label for="my-drawer-4" class="drawer-button btn btn-primary">
            Filter <FaFilter />
          </label>
        </div>
        <div class="drawer-side">
          <label
            for="my-drawer-4"
            aria-label="close sidebar"
            class="drawer-overlay"
          ></label>
          <ul class="menu bg-base-200 text-base-content min-h-full w-80 p-4">
            <li>
              <a>Sidebar Item 1</a>
            </li>
            <li>
              <a>Sidebar Item 2</a>
            </li>
          </ul>
        </div>
      </div> */}

      {/* <button
        className={`${
          "all" === selectedCategory.name ? "" : "btn-outline"
        } ${catButtonClasses}`}
        onClick={() => onCategoryClick(allCategory)}
      >
        {"ALL"}
      </button> */}
      {/* <CategoryButton
        category={{ name: "ALL" }}
        selectedCategory={selectedCategory}
        onCategoryClick={() => onCategoryClick(allCategory)}
      /> */}
      {/* {categories &&
        categories.map((category, index) => {
          if (category.name !== "Uncategorized") {
            return (
              <button
                key={index} 
                className={`${
                  category.name === selectedCategory.name ? "" : "btn-outline"
                } ${catButtonClasses}`}
                onClick={() => onCategoryClick(category)}
              >
                <span className="font-abel">{category.name}</span>
              </button>
            );
          }
        })} */}
    </div>
  );
}

export default React.memo(PortfolioFilter);
