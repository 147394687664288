import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Loading from "./Loading";
import { useFetch } from "../api/useFetch";
import Head from "./Head";
import { FaMapSigns } from "react-icons/fa";
import { BsFillTelephoneFill, BsGlobeAmericas } from "react-icons/bs";
import { FaPaperPlane } from "react-icons/fa";

import ReactGA from "react-ga4";

// import { useFormik } from "formik";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";

function Contact(props) {
  let page = props.data;

  const seo = page?.yoast_head_json;
  const seoTitle = seo?.title;
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location,
      title: seoTitle,
    });
  }, [location, seoTitle]);

  const [contactSuccess, setContactSuccess] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("form.current", form.current);
    emailjs
      .sendForm(
        "service_mp49enu",
        "template_ssdzdfc",
        form.current,
        "uoBym-lZ9Y0Usmbj3"
      )
      .then(
        () => {
          form.current.reset();
          setContactSuccess(true);
          console.log("success");
        },
        (error) => {
          console.log("failed...", error.text);
        }
      );
  };

  const formTab = [
    { label: "Name", name: "name", type: "text", pattern: "", required: true },
    {
      label: "Email",
      name: "email",
      type: "email",
      pattern: /^\S+@\S+$/i,
      required: true,
    },
    {
      label: "Message",
      name: "message",
      type: "textarea",
      pattern: "",
      required: true,
    },
  ];

  if (!page) return <Loading />;

  return (
    page && (
      <>
        <Head
          title={seo.title}
          description={seo.og_description}
          keywords=""
          url={page.link}
          canonical={page.link}
          ogImagePath=""
          seo={seo}
        />
        <div className="container prose lg:prose-2xl prose-slate">
          <h1 className="no-underline uppercase font-abel">
            {page.title.rendered}
          </h1>
          <div
            className="font-abel"
            dangerouslySetInnerHTML={{ __html: page.content.rendered }}
          />
          {contactSuccess ? (
            <div className="container prose lg:prose-2xl prose-slate">
              <h3>Thanks for reaching out, talk soon.</h3>
            </div>
          ) : (
            <div className=" container mx-auto flex flex-wrap shadow-2xl my-20 rounded-md p-5 justify-center">
              <div className="lg:w-1/2 w-full p-4">
                <form
                  ref={form}
                  className="shadow-md rounded-lg px-2 pt-6 pb-8 mb-4"
                  onSubmit={sendEmail}
                >
                  <div className="flex  flex-col">
                    {formTab.map((x, index) => {
                      return (
                        <div
                          key={index}
                          className="mx-auto form-control w-full"
                        >
                          <label className="label">
                            <span className="label-text">{x.label}</span>
                          </label>
                          {x.type !== "textarea" ? (
                            <input
                              // {...register(x.name, {
                              //   required: x.required,
                              //   pattern: x.pattern,
                              // })}
                              type={x.type}
                              placeholder={x.label}
                              name={x.name}
                              className="input input-bordered w-full placeholder-base-content"
                              // onChange={(e) =>
                              //   handleChange({ [x.name]: e.target.value })
                              // }
                            />
                          ) : (
                            <textarea
                              // {...register(x.name)}
                              placeholder={x.label}
                              name={x.name}
                              className="input input-bordered w-full placeholder-base-content"
                              // onChange={(e) =>
                              //   handleChange({ [x.name]: e.target.value })
                              // }
                              // onChange={handleSubmit(onSubmit)}
                            />
                          )}
                        </div>
                      );
                    })}
                    <div className="w-full my-4 flex justify-end ">
                      <button className="btn w-full" type="submit">
                        Send Message
                        <span>
                          <FaPaperPlane />
                        </span>
                      </button>
                    </div>
                  </div>
                  {/* <ReCAPTCHA
                  sitekey="6LeJZaUoAAAAAOX1j8jtySqzroR1d7RSV5P1TPC6"
                  onChange={onChange}
                /> */}
                </form>
              </div>
            </div>
          )}
        </div>
      </>
    )
  );
}

export default Contact;
