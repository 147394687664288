import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useFetch, useFetchAssets } from "../api/useFetch";
import Loading from "./Loading";
import Image from "./Image";
import Hero from "./Hero";
import { Helmet } from "react-helmet";
import Head from "./Head";
import Asset from "./Asset";

import ReactGA from "react-ga4";

function PortfolioItem(props) {
  const location = useLocation();

  let slug = location.pathname.split("/portfolio/")[1];
  const portfolioItems = useFetch(
    `https://wp.danieljkahn.com/wp-json/wp/v2/portfolio?slug=${slug}`
  );
  // let assets = useFetchAssets(
  //   `https://wp.danieljkahn.com/wp-json/wp/v2/media/${portfolioItem?.acf.assets}`
  // );
  let portfolioItem = portfolioItems?.filter((i) => {
    return i.slug === slug;
  })[0];
  let seo = portfolioItem?.yoast_head_json;

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location,
      title: portfolioItem?.title.rendered,
    });
  }, [portfolioItem, location]);

  if (!portfolioItem) return <Loading />;

  return (
    <>
      <Head
        title={seo.title}
        description={seo.og_description}
        keywords=""
        url={portfolioItem?.link}
        canonical={portfolioItem?.link}
        ogImagePath=""
        seo={seo}
      />

      <article className="w-full">
        <Hero
          title={portfolioItem?.title?.rendered}
          imageId={portfolioItem?.featured_media}
        />
        <div className="container prose lg:prose-2xl prose-slate">
          <div className="assets">
            {portfolioItem?.acf?.assets?.map((asset, index) => {
              return <Asset assetId={asset.asset} key={index} />;
            })}
          </div>
          <div
            className="font-abel"
            dangerouslySetInnerHTML={{
              __html: portfolioItem?.content.rendered,
            }}
          />
          {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        
      </div> */}
        </div>
      </article>
    </>
  );
}

export default PortfolioItem;
