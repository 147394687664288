import React, { useEffect, useState, memo } from "react";
import { Link, useParams } from "react-router-dom";
import { useFetch } from "../api/useFetch";
import Loading from "./Loading";
import CategoryButton from "./CategoryButton";

import { FaFilter } from "react-icons/fa";

function CategorySorter({ selectedCategory, onCategoryClick }) {
  // const portfolio = useFetch(
  //   "https://wp.danieljkahn.com/wp-json/wp/v2/portfolio"
  // );
  const categories = useFetch(
    "https://wp.danieljkahn.com/wp-json/wp/v2/categories"
  );
  const allCategory = {
    name: "all",
    id: 0,
  };

  const catButtonClasses = "btn m-0.5 drop-shadow-xl hover:drop-shadow-xl";
  if (!categories) return <Loading />;
  return (
    <div>
      {/* <button
        className={`${
          "all" === selectedCategory.name ? "" : "btn-outline"
        } ${catButtonClasses}`}
        onClick={() => onCategoryClick(allCategory)}
      >
        {"ALL"}
      </button> */}
      <CategoryButton
        category={{ name: "all" }}
        selectedCategory={selectedCategory}
        onCategoryClick={() => onCategoryClick(allCategory)}
      />
      {categories &&
        categories.map((category, index) => {
          if (category.name !== "Uncategorized") {
            return (
              <button
                key={index} 
                className={`${
                  category.name === selectedCategory.name ? "" : "btn-outline"
                } ${catButtonClasses}`}
                onClick={() => onCategoryClick(category)}
              >
                <span className="font-abel">{category.name}</span>
              </button>
            );
          }
        })}
    </div>
  );
}

export default React.memo(CategorySorter);
