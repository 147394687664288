import React from "react";
import Helmet from "react-helmet";

function Head({ title, description, keywords, url, canonical, ogImagePath }) {
  url = (url || url.length == 0) ? url : 'https://danieljkahn.com';
  canonical = canonical ?? url;
  ogImagePath = ogImagePath ? ogImagePath : 'https://danieljkahn.com/android-chrome-512x512.png';
  let afterTitle = ' | Daniel J. Kahn';
  return (
    <>
      <Helmet
        link={[
          {
            rel: "icon",
            type: "image/x-icon",
            href: "/favicon.ico",
          },
          {
            rel: "icon",
            type: "image/png",
            href: "/favicon-16x16.png",
          },
          {
            rel: "icon",
            type: "image/png",
            href: "/favicon-32x32.png",
            sizes: "32x32",
          },
          {
            rel: "icon",
            type: "image/png",
            href: "/android-chrome-192x192.png",
            sizes: "180x180",
          },
          {
            rel: "icon",
            type: "image/png",
            href: "/android-chrome-512x512.png",
            sizes: "180x180",
          },
          {
            rel: "apple-touch-icon",
            type: "image/png",
            href: "/apple-touch-icon.png",
            sizes: "180x180",
          }
        ]}
      >
        <title data-react-helmet="true">
          {title}{afterTitle}
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />

        <meta
          property="og:image"
          content={ogImagePath}
        />
        <meta property="og:image:width" content="638" />
        <meta
          property="og:url"
          content={url}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={title}
        />
        <meta
          property="og:description"
          content={description}
        />

        <link rel="canonical" href={canonical} />
      </Helmet>
    </>
  );
}

export default Head;
