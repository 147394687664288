import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFetch } from "../api/useFetch";
import Loading from "./Loading";
import Image from "./Image";

function PortfolioItemCard(props) {
  const portfolioItem = props.portfolioItem;
  
  return (
    <div className="p-4">
      <Link
        to={"/portfolio/" + portfolioItem.slug}
        data-categories={JSON.stringify(portfolioItem.categories.concat(0))}
        className="card bg-base-100 shadow-md hover:shadow-xl rounded-md overflow-hidden"
        state={portfolioItem}
      >
        <div>
          <Image imageId={portfolioItem.featured_media} size={'card'} />
          <div className="card-body px-3 py-4">
            <h2
              dangerouslySetInnerHTML={{
                __html: portfolioItem.title.rendered,
              }}
              className="card-title font-abel"
            ></h2>
            <p></p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default PortfolioItemCard;
