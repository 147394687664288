import { useEffect } from "react";
import { useState, useMemo } from "react";

export function useFetch(url) {
  const [data, setData] = useState(null);
  useEffect(() => {
    async function loadData() {
      const response = await fetch(url);
      if (!response.ok) {
        return;
      }
      const posts = await response.json();
      setData(posts);
    }
    loadData();
  }, [url]);
  return data;
}

export function useFetchMemo(url) {
  const data = useMemo(async () => {
    console.log("url", url);
    let fetchedData = await fetch(url);
    // .then((response) => response.json())
    // .then((json) => {
    //   console.log('json',json);
    //   fetchedData = json;
    // });
    return await fetchedData.json();
  }, [url]);

  return data;
}

export function useFetchAssets(url) {
  const [data, setData] = useState(null);
  useEffect(() => {
    async function loadData() {
      const response = await fetch(url);
      if (!response.ok) {
        return;
      }
      const posts = await response.json();
      setData(posts);
    }
    loadData();
  }, [url]);
  return data;
}

export function useFetchLiveReload(url) {
  const [data, setData] = useState(null);
  useEffect(() => {
    async function loadData() {
      const response = await fetch(url);
      if (!response.ok) {
        return;
      }
      const posts = await response.json();
      setData(posts);
    }
    loadData();
  }, [data]);
  return data;
}

// export function usePosts(url) {
//   const [data, setData] = useState(null);
//   useEffect(() => {
//     async function loadPosts() {
//       const response = await fetch(
//         url
//       );
//       if (!response.ok) {
//         return;
//       }
//       const posts = await response.json();
//       setData(posts);
//     }
//     loadPosts();
//   },[url]);
// }
