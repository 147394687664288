import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import Page from "./components/Page";
import Posts from "./components/Posts";
import Header from "./components/Header";
import Head from "./components/Head";
import Portfolio from "./components/Portfolio";
import PortfolioItem from "./components/PortfolioItem";
import Contact from "./components/Contact";
import { useFetch } from "./api/useFetch";
import Footer from "./components/Footer";

import ReactGA from "react-ga4";
const TRACKING_ID = "G-D68FBRB9BR";

function App() {
  // const location = useLocation();
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: location.pathname,
    //   title: "DJK - Home",
    // });
  }, []);
  // const [pages, setPages] = useState({});
  let pages = useFetch("https://wp.danieljkahn.com/wp-json/wp/v2/pages");
  // quick error handling
  if (!pages) return;

  return (
    <Router>
      <div className="App h-full w-full relative">
        <Head
          title="Daniel J. Kahn"
          description=""
          keywords=""
          url=""
          canonical=""
          ogImagePath=""
        />
        <Header />
        <Routes>
          <Route
            path="/"
            element={<Page data={getPageDataBySlug(pages, "home")} />}
          />
          <Route
            path="/posts"
            element={<Posts title={"Posts"} slug={"posts"} />}
          />
          <Route
            path="/about"
            element={<Page data={getPageDataBySlug(pages, "about")} />}
          />
          <Route
            path="/contact"
            element={<Contact data={getPageDataBySlug(pages, "contact")} />}
          />
          <Route
            path="/portfolio"
            element={<Portfolio title={"Portfolio"} />}
          />
          <Route
            path="/portfolio/:slug"
            element={<PortfolioItem title={"Portfolio Item"} />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
function getPageDataBySlug(pages, slug) {
  let p = pages.filter((i) => {
    return i.slug === slug;
  })[0];
  return p;
}

export default App;
