import React, { Component } from "react";
import loadingIcon from "../images/icon-loading.svg";

class Loading extends Component {
  render() {
    return (
      <span className="loading loading-ring loading-lg"></span>
      // <div className="loading absolute top-0 bottom-0 left-0 right-0 m-auto h-10 w-10">
      //       <img className="h-full w-full" src={loadingIcon} alt="Loading..." />
      //   </div>
    );
  }
}

export default Loading;
