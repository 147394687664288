import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useFetch, useFetchMemo } from "../api/useFetch";
import Loading from "./Loading";
import PortfolioItemCard from "./PortfolioItemCard";
import Head from "./Head";
import CategorySorter from "./CategorySorter";
import PortfolioFilter from "./PortfolioFilter";

import ReactGA from "react-ga4";

function Portfolio(props) {
  const location = useLocation();

  const portfolio = useFetch(
    "https://wp.danieljkahn.com/wp-json/wp/v2/portfolio"
  );
  const allCategory = {
    name: "all",
    id: 0,
  };
  const [selectedCategory, setSelectedCategory] = useState(allCategory);
  const [filterSelections, setFilterSelections] = useState({
    categories: [],
    tags: [],
  });
  const [filteredPortfolio, setFilteredPortfolio] = useState(portfolio);

  // const filterPortfolio = useCallback(() => {

  // })

  const handleCategoryClick = useCallback((category) => {
    setSelectedCategory(category);
  }, []);
  const handleFilterUpdate = useCallback((updatedFilterSelections) => {
    setFilterSelections(updatedFilterSelections);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location,
      title: "Portfolio Index",
    });

    setFilteredPortfolio((prev) => {
      const updatedPortfolio = portfolio?.filter((i) => {
        const cats = i.categories;
        const tags = i.tags;

        // const hasCats = cats.some((catId) =>
        //   filterSelections?.categories?.some(
        //         (category) => category.id === catId
        //       )
        // );

        const hasTags = tags.some((tagId) =>
          filterSelections?.tags?.some((tag) => tag.id === tagId)
        );
        return hasTags;
      });
      return updatedPortfolio;
    });
  }, [portfolio, location, filterSelections]);
  if (!portfolio) return <Loading />;
  return (
    <>
      <Head
        title="Portfolio | Daniel J. Kahn"
        description="Art, Code, Sound"
        keywords=""
        url="https://danieljkahn.com/portfolio"
        canonical="https://danieljkahn.com/portfolio"
        ogImagePath=""
        // seo={seo}
      />
      <div className="flex flex-col justify-center md:flex-row">
      <CategorySorter
        selectedCategory={selectedCategory}
        onCategoryClick={handleCategoryClick}
      />
      <PortfolioFilter
        filterSelections={filterSelections}
        updateFilterSelections={handleFilterUpdate}
      />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredPortfolio &&
          filteredPortfolio.map((portfolioItem, index) => {
            if (
              portfolioItem &&
              (portfolioItem.categories.includes(selectedCategory.id) ||
                selectedCategory.id === 0)
            )
              return (
                <PortfolioItemCard portfolioItem={portfolioItem} key={index} />
              );
          })}
      </div>
    </>
  );
}

export default Portfolio;
