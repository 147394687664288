import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useFetch } from "../api/useFetch";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import ReactGA from "react-ga4";

function Posts(props) {
  const posts = useFetch("https://wp.danieljkahn.com/wp-json/wp/v2/posts");
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location,
      title: "Posts Index",
    });
  }, [location]);

  return (
    <Grid container spacing={2}>
      {posts &&
        posts.map((post, index) => {
          return (
            <Grid item xs={4} key={index}>
              log(post)
              <Card>
                <CardContent>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                  />
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      {/* <Grid item xs={12}>
        xs=12
      </Grid>
      <Grid item xs={8}>
        xs=8
      </Grid> */}
    </Grid>
  );
}

export default Posts;
